import Amplify from '@aws-amplify/core';

// Enable Amplify debugging for better insights when doing local dev.
(window as any).LOG_LEVEL = 'DEBUG';

/**
 * The client id query parameter
 */
const CLIENT_ID_QUERY_PARAMETER = 'client_id';

/**
 * The list of query parameters that should not be forwarded to the sign-in UI.
 */
const URL_OPENER_EXCLUDED_QUERY_PARAMETERS = [CLIENT_ID_QUERY_PARAMETER];

/**
 * The list of client ids that are allowed to be used in the Staging Website as overrides of the default one.
 * TODO: Move this configuration to GandalfClientConfiguration and consume it from there.
 */
const CLIENT_IDS_ALLOWLIST = [
  // GandalfHCProdUAT
  'knnmepn0b5a371peta4mg6013', // Gamma IAD
  '5u198f8d6ad8n49qhttm1e6q81', // Gamma DUB
  '2r8shl8i11u3120bsoeng46t2k', // Gamma PDX
  '45jm7pm2n7jedgi7m7cngkto44', // Gamma NRT
  '5tg2bb096s519eo09udh0pqoop', // Gamma SYD
  '1fpmboppod08s57tqmdi87r0pv', // Prod IAD
  '1ivbc2b1t8fcvhbljn905s3olm', // Prod DUB
  '1k7f7pibil9h2tvr28rudcgbuq', // Prod PDX
  '61rm5tq593p748lg0hnpb8r5b8', // Prod NRT
  '698kq5rv71mqv1mhaqvouh6fef', // Prod SYD

  // GandalfHCProdUATProfile
  '73av83e14oq84ffidmo61mte2m', // Gamma IAD
  '10j6ap4f70ha7vpeuub28fcrv3', // Gamma DUB
  '2mei95c3oicgllf22ql3niah2p', // Gamma PDX
  '55p4rvrbom52ovkrkhh9n2a9lq', // Gamma NRT
  '62d42eb13jokne71bq2cajhupn', // Gamma SYD
  '75ac1lut5lc19pb9mlq0modlvg', // Prod IAD
  '6k5smto18nanctj4o3ad5lk4nk', // Prod DUB
  '6chrfahokhgvhsteiirjinomd1', // Prod PDX
  'tju5ucvt41mg6pksjck1livd9', // Prod NRT
  '38pj5340nqa9mem6s1scdrf16c', // Prod SYD
];

const addUrlParameters = (url: string): string => {
  const urlObj = new URL(url);
  const params = new URLSearchParams(window.location.search);
  params.forEach((value, key) => {
    if (!URL_OPENER_EXCLUDED_QUERY_PARAMETERS.includes(key)) {
      urlObj.searchParams.append(key, value);
    }
  });
  return urlObj.toString();
};

const generateNewUrl = (
  domain: string,
  signInUIDomain: string,
  url: string
): string => {
  const loginRequestUrl = `https://${domain}/oauth2/authorize`;
  let newUrl = url;
  if (signInUIDomain && url.startsWith(loginRequestUrl)) {
    newUrl = url.replace(loginRequestUrl, `https://${signInUIDomain}/login`);
  }
  return addUrlParameters(newUrl);
};

// Creates a urlOpener function to intercept sign in calls and change the url.
// See docs, https://aws-amplify.github.io/amplify-js/api/interfaces/awscognitooauthopts.html
const createUrlInterceptor =
  (domain: string, signInUIDomain: string) => (url: string) => {
    const newUrl = generateNewUrl(domain, signInUIDomain, url);
    const windowProxy = window.open(newUrl, '_self');
    return windowProxy ? Promise.resolve(windowProxy) : Promise.reject();
  };

const getAmplifyConfig = (config: any, useCustomSignInUI: boolean): any => {
  const defaultConfig = {
    Auth: {
      region: config.Auth.region,
      userPoolId: config.Auth.userPoolId,
      userPoolWebClientId: config.Auth.userPoolWebClientId,
      oauth: {
        domain: config.Auth.oauth.domain,
        scope: config.Auth.oauth.scope,
        responseType: config.Auth.oauth.responseType,
        redirectSignIn: config.Auth.oauth.redirectSignIn,
        redirectSignOut: config.Auth.oauth.redirectSignOut,
        // (optional) This is used to intercept login requests to navigate to the Custom SignIn UI.
        ...(useCustomSignInUI && {
          urlOpener: createUrlInterceptor(
            config.Auth.oauth.domain,
            config.Gandalf.customSignInDomain
          ),
        }),
      },
    },
  };
  const params = new URLSearchParams(window.location.search);
  const clientIdOverride = params.get(CLIENT_ID_QUERY_PARAMETER);
  if (!clientIdOverride || !CLIENT_IDS_ALLOWLIST.includes(clientIdOverride)) {
    return defaultConfig;
  }
  const redirectSignInUrlObj = new URL(defaultConfig.Auth.oauth.redirectSignIn);
  redirectSignInUrlObj.searchParams.append(
    CLIENT_ID_QUERY_PARAMETER,
    clientIdOverride
  );
  return {
    ...defaultConfig,
    Auth: {
      ...defaultConfig.Auth,
      userPoolWebClientId: clientIdOverride,
      oauth: {
        ...defaultConfig.Auth.oauth,
        redirectSignIn: redirectSignInUrlObj.toString(),
      },
    },
  };
};

const configureAmplify = ({
  config,
  useCustomSignInUI,
}: {
  config: any;
  useCustomSignInUI: boolean;
}) => {
  return Amplify.configure(getAmplifyConfig(config, useCustomSignInUI));
};

export default configureAmplify;
